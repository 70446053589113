export const useGetTranslatedCamperOptionRecurrence = () => {
  const { $i18n: { t } } = useNuxtApp()

  return {
    getTranslatedCamperOptionRecurrence: (recurrence: string | null | undefined) => {
      if (!recurrence) {
        return null
      }

      const recurrenceMap: Record<string, string> = {
        booking: t('data.product.options.recurrence.options.booking'),
        day: t('data.product.options.recurrence.options.day'),
      }

      return recurrenceMap[recurrence.toLowerCase()] || recurrence
    },

  }
}
